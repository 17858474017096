import React, { lazy, Suspense, useEffect, useState} from "react";
import cls from "./App.module.scss";
import "react-toastify/dist/ReactToastify.css";
import { Route, Switch, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTransition, animated } from "react-spring";
import { Container, CircularProgress } from "@material-ui/core";
import json from "../package.json";
import types from './redux/Types'
import Footer from "./pages/Footer";
import Header from "./pages/Header";
import Page404 from "./pages/Page404";
import Login from "./pages/Login";
import Restore from "./pages/Restore";
import authentication from "./redux/actions/authentication";
import services from "./redux/actions/services";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import UserRoleType from "./roleType"
import SelectOrganization from "./components/SelectOrganization";

const HistoryOrders = lazy(() => import("./pages/HistoryOrders"));
const FAQ = lazy(() => import("./pages/FAQ"));
const Equipment = lazy(() => import("./pages/Equipment"));
const Orders = lazy(() => import("./pages/Orders"));
const NewOrderPage = lazy(() => import("./pages/NewOrderPage"));
const ArchivePage =  lazy(() => import("./pages/ArchivePage"));
const ActPage = lazy(() => import("./pages/ActPage"));
const ServicePage = lazy(() => import("./pages/Service"));
const RentPage = lazy(() => import("./pages/RentPage"));
const PreOrder = lazy(() => import("./pages/PreOrder"));
const InformationUser = lazy(() => import("./pages/InformationUser"));
const MultiAgreementPage = lazy(() => import("./pages/MultiAgreementPage"));
const Transactions = lazy(() => import("./pages/Transactions"));
const Settings = lazy(() => import("./pages/Settings"));

const App = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const history = useHistory();
	const roleType = UserRoleType;
	const { isAuthorized, isLoggedIn, unp, role, needRefreshUserInfo, stateRefresher} = useSelector((state) => state.authentication);	
	const transitions = useTransition(location, (location) => location.pathname, {
		from: {
			opacity: 0,
			position: "absolute",
			width: "90%",
			left: "50vw",
		},
		enter: {
			position: "inherit",
			opacity: 1,
			width: "100%",
			left: "0vw",
		},
		leave: {
			position: "absolute",
			opacity: 0,
			width: "90%",
			left: "-50vw",
		},
		unique: false,
		reset: true,
		// config: { duration: 300 }
	});
	const [authenticated, setAuthentificated] =  useState(true)
	const [unpSelected, setUnpSelected] =  useState(true)

	useEffect(() => {	
		//check token first
		let token = localStorage.getItem("token")
		if(!token){
			setAuthentificated(false)
			return
		}
		setAuthentificated(true)
		//check selected unp
		let selectedUnp = localStorage.getItem("unp")
		if(!selectedUnp){
			setUnpSelected(false)
			return
		}
		setUnpSelected(true)

		//check admin
		let isAdmin = +localStorage.getItem('isAdmin')	
		if(isAdmin){
			dispatch({ type: types.USER_IS_ADMIN});
		}

		if(isAuthorized){
			return
		}

		let agrList = []
		try {
			agrList = JSON.parse(localStorage.getItem("argList"))
		} catch {
			//ignore			
		}

		if (agrList && agrList.length) {
			dispatch({ type: types.USER_AGREEMENT_SUCCESS, agreementList: agrList });    
			let agrId = +localStorage.getItem("agrId")  
			if (!!agrId && agrList.find(x => x.agrID === agrId)){
				dispatch({type: types.USER_AGREEMENT_CHANGED, agrId: agrId})		
			}
			loginPostProcessing(agrList)
		} else {
			dispatch(authentication.agreementList((x) => loginPostProcessing(x, true)))				
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unp, isLoggedIn, isAuthorized, stateRefresher]);

	const loginPostProcessing = (data, applyRedirect) => {
		if(!data){
			dispatch({ type: types.USER_IS_NOT_LOGGED_IN})	
			return
		}
		let userRole = undefined;

		switch(data.length){
			case 0:
				userRole = roleType.None;
				break;
			case 1:
				switch(data[0].agrType){
					case "Услуги. СО":
						userRole = roleType.CO;
						break;
					case "Аренда":
						userRole = roleType.Rent;
						break;
					case "Услуги. SaaS":
						userRole = roleType.SaaS;
						break;
					case "СО+SAAS":
						userRole = roleType.СОSAAS;
						break;
					default:
						break;
				}
				break;
			default:				
				userRole = roleType.Multi
				break;
		}

		if (!userRole){
			dispatch({ type: types.USER_IS_NOT_LOGGED_IN})	
			return
		}
		//get final role
		const defineRedirect = () => {	
			localStorage.setItem("role", userRole)
			dispatch({ type: types.USER_IS_AUTHORIZED, role:userRole})	
			if(applyRedirect){
				if (userRole === roleType.Multi){						
					history.push("/")
					return
				}
				//todo maybe need to process admin with /settings redirect
			}
	
			dispatch(services.preOrder((el) => {
				let targetUrl = ""
				if (el) {
					dispatch({type: types.USER_HAS_PREORDER})
					targetUrl = "/preorder"			
				} else {
					if (userRole === roleType.SaaS){						
						targetUrl = "/equipment"	
					}					
				}
				if (targetUrl && applyRedirect){
					history.push(targetUrl)
				}
			}))
						
		}
		dispatch(authentication.userInfo(defineRedirect,defineRedirect))
	}

	useEffect(() => {
		if(needRefreshUserInfo){
			dispatch(authentication.agreementList((_)=> {
				dispatch({ type: types.USER_REFRESH_SUCCESS})			
			}))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [needRefreshUserInfo])


	if (!authenticated){
		return (
					<div className={cls.App}>
						<Switch>
							<Route path='/restore' component={Restore} />
							<Route path='*' component={Login} />
						</Switch>
						<div className={cls.version}>v. {json.version}</div>
					</div>
				)
	}

	if(!unpSelected){
		return  (<SelectOrganization/>)
	}

	if(!isAuthorized){
		return  (<CircularProgress className={cls.global_loading}/>)
	}

	return (
		<div
			className={`${cls.App} ${
				location.pathname === "/equipment" ||
				location.pathname === "/orders" ||
				location.pathname === "/preorder"
					? cls.App_overflow
					: ""
			}`}
		>
			<Header/>
			<div className={cls.home_content}>
				<Container
					style={{ position: "relative" }}
					component='main'
					maxWidth='lg'
				>
					<Suspense fallback="">
						{transitions.map(({ item, props: transition, key }) => (
							<animated.div id='animated' key={key} style={transition} className={cls.animated_container}>
								<Switch location={item}>

									{  (role === roleType.СОSAAS || role === roleType.SaaS) && <Route exact path='/' component={InformationUser} />}
									{  role === roleType.Rent && <Route exact path='/' component={RentPage} />}
									{  role === roleType.CO  && <Route exact path='/' component={ServicePage} />}									
									{  role === roleType.Multi && <Route exact path='/' component={MultiAgreementPage} />}
									{  role === roleType.None && <Route exact path='/' component={Transactions} />}
									{  role === roleType.Admin && <Route exact path='/' component={Transactions} />}
									
									<Route exact path='/FAQ' component={FAQ} />
									<Route exact path='/rent' component={RentPage} />
									<Route exact path='/service' component={ServicePage} />
									<Route exact path='/transactions'component={Transactions}/>
									<Route exact path='/equipment' component={Equipment} />
									<Route exact path='/acts' component={ActPage} />
									<Route exact path='/newOrder' component={NewOrderPage} />
									<Route exact path='/archive' component={ArchivePage} />
									<Route exact path='/preorder' component={PreOrder} />										
									<Route exact path='/orders' component={Orders} />
									<Route exact path='/history' component={HistoryOrders} />
									<Route exact path='/settings' component={Settings} />
									<Route exact component={Page404} />
								</Switch>
							</animated.div>
						))}
					</Suspense>
				</Container>
			</div>
			

			<Footer />
		</div>
	);
};

export default App;
