import React from "react";
import cls from "./ModalContentInput.module.scss";
import { useTranslation } from "react-i18next";
import { FormControl, TextField } from "@material-ui/core";

const ModalContentInput = ({ value, onChange }) => {
	const { t } = useTranslation();

	return (
		<div className={cls.modal_content_wrapper_input}>
			<div className={cls.modal_content_head}>
				<h2>{t("Введите УНП")}</h2>
			</div>
			<div className={cls.modal_content_main}>
				<FormControl variant='outlined' fullWidth={true}>
					<TextField
						type='number'
						label='УНП'
						variant='outlined'
						value={value}
						onChange={onChange}
					/>
				</FormControl>
			</div>
		</div>
	);
};

export default ModalContentInput;
