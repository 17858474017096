// eslint-disable-next-line import/no-anonymous-default-export
export default {
    //auth
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_REQUEST_SUCCESS: 'USER_REQUEST_SUCCESS',
    USER_REQUEST: 'USER_REQUEST',
    USER_REQUEST_ERROR: 'USER_REQUEST_ERROR',    
    USER_ORG_SELECTED: 'USER_ORG_SELECTED',
    USER_IS_ADMIN: 'USER_IS_ADMIN',
    USER_IS_AUTHORIZED: 'USER_IS_AUTHORIZED',
    USER_IS_NOT_LOGGED_IN: 'USER_IS_NOT_LOGGED_IN',
    USER_HAS_PREORDER: "USER_HAS_PREORDER",

    //try to get agreement list
    USER_NEED_REFRESH: "USER_NEED_REFRESH",
    USER_REFRESH_SUCCESS: "USER_REFRESH_SUCCESS",
    USER_AGREEMENT_REQUEST: 'USER_AGREEMENT_REQUEST',
    USER_AGREEMENT_SUCCESS: 'USER_AGREEMENT_SUCCESS',
    USER_AGREEMENT_CHANGED: 'USER_AGREEMENT_CHANGED',
    USER_AGREEMENT_ERROR: 'USER_AGREEMENT_ERROR',
    USER_ORGLIST_REQUEST_SUCCESS: 'USER_ORGLIST_REQUEST_SUCCESS',
    //user info
    USER_INFO_REQUEST: 'USER_INFO_REQUEST',
    USER_INFO_REQUEST_SUCCESS: 'USER_INFO_REQUEST_SUCCESS',
    USER_INFO_REQUEST_ERROR: 'USER_INFO_REQUEST_ERROR',
    //transactions acquiring
    TRANSACTIONS_REQUEST: 'TRANSACTIONS_REQUEST',
    TRANSACTIONS_REQUEST_ERROR: 'ACQUIRING_REQUEST_ERROR',
    TRANSACTIONS_DEVICES_REQUEST_SUCCESS: 'TRANSACTIONS_DEVICES_REQUEST_SUCCESS',
    TRANSACTIONS_LIST_REQUEST_SUCCESS: 'TRANSACTIONS_LIST_REQUEST_SUCCESS',
    TRANSACTIONS_BATCH_LIST_REQUEST_SUCCESS: 'TRANSACTIONS_BATCH_LIST_REQUEST_SUCCESS',
    TRANSACTIONS_EMAIL_BATCH_REQUEST_SUCCESS: 'TRANSACTIONS_EMAIL_BATCH_REQUEST_SUCCESS',
    TRANSACTIONS_BATCH_TRAN_LIST_REQUEST_SUCCESS: 'TRANSACTIONS_BATCH_TRAN_LIST_REQUEST_SUCCESS',
    TRANSACTIONS_EMAIL_BATCH_TRAN_REQUEST_SUCCESS: 'TRANSACTIONS_EMAIL_BATCH_TRAN_REQUEST_SUCCESS',
    TRANSACTIONS_ANALYTIC_REQUEST_SUCCESS: 'TRANSACTIONS_ANALYTIC_REQUEST_SUCCESS',
    TRANSACTIONS_ORDER_LIST_REQUEST_SUCCESS: 'TRANSACTIONS_ORDER_LIST_REQUEST_SUCCESS',
    TRANSACTIONS_ORDER_LIST_UP_REQUEST_SUCCESS: 'TRANSACTIONS_ORDER_LIST_UP_REQUEST_SUCCESS',
    TRANSACTIONS_ORDER_LIST_DOWN_REQUEST_SUCCESS: 'TRANSACTIONS_ORDER_LIST_DOWN_REQUEST_SUCCESS',
    //settings
    SETTINGS_REQUEST: 'SETTINGS_REQUEST',
    SETTINGS_REQUEST_ERROR: 'SETTINGS_REQUEST_ERROR',
    SETTINGS_ADD_USER_SUCCESS: 'SETTINGS_ADD_USER_SUCCESS',
    SETTINGS_EDIT_USER_SUCCESS: 'SETTINGS_EDIT_USER_SUCCESS',
    SETTINGS_DEL_USER_SUCCESS: 'SETTINGS_DEL_USER_SUCCESS',
    SETTINGS_LIST_USER_SUCCESS: 'SETTINGS_LIST_USER_SUCCESS',
    SETTINGS_GET_EMAIL_SUCCESS: 'SETTINGS_GET_EMAIL_SUCCESS',
    SETTINGS_EDIT_EMAIL_SUCCESS: 'SETTINGS_EDIT_EMAIL_SUCCESS',
    //sevice
    SERVICES_REQUEST_SUCCESS: 'SERVICES_REQUEST_SUCCESS',
    SERVICES_REQUEST: 'SERVICES_REQUEST',
    SERVICES_REQUEST_ERROR: 'SERVICES_REQUEST_ERROR',
    //device
    DEVICE_REQUEST_SUCCESS: 'DEVICE_REQUEST_SUCCESS',
    DEVICE_REQUEST_DISABLE: 'DEVICE_REQUEST_DISABLE',
    DEVICE_REQUEST: 'DEVICE_REQUEST',
    DEVICE_REQUEST_ERROR: 'DEVICE_REQUEST_ERROR',
    //current device
    CURRENT_DEVICE_REQUEST_SUCCESS: 'CURRENT_DEVICE_REQUEST_SUCCESS',
    CURRENT_DEVICE_REQUEST: 'CURRENT_DEVICE_REQUEST',
    CURRENT_DEVICE_REQUEST_ERROR: 'CURRENT_DEVICE_REQUEST_ERROR',
    //current device disable
    CURRENT_DEVICE_DISABLE_SUCCESS: 'CURRENT_DEVICE_DISABLE_SUCCESS',
    CURRENT_DEVICE_DISABLE_REQUEST: 'CURRENT_DEVICE_DISABLE_REQUEST',
    CURRENT_DEVICE_DISABLE_ERROR: 'CURRENT_DEVICE_DISABLE_ERROR',
    //current device exclude
    CURRENT_DEVICE_EXCLUDE_SUCCESS: 'CURRENT_DEVICE_EXCLUDE_SUCCESS',
    CURRENT_DEVICE_EXCLUDE_REQUEST: 'CURRENT_DEVICE_EXCLUDE_REQUEST',
    CURRENT_DEVICE_EXCLUDE_ERROR: 'CURRENT_DEVICE_EXCLUDE_ERROR',
    //current device bind
    CURRENT_DEVICE_BIND_SUCCESS: 'CURRENT_DEVICE_BIND_SUCCESS',
    CURRENT_DEVICE_BIND_REQUEST: 'CURRENT_DEVICE_BIND_REQUEST',
    CURRENT_DEVICE_BIND_ERROR: 'CURRENT_DEVICE_BIND_ERROR',
    //prepareOrderPay
    PREPARE_AN_ORDER_PAY_REQUEST_SUCCESS: 'PREPARE_AN_ORDER_PAY_REQUEST_SUCCESS',
    PREPARE_AN_ORDER_PAY_REQUEST: 'PREPARE_AN_ORDER_PAY_REQUEST',
    PREPARE_AN_ORDER_PAY_REQUEST_ERROR: 'PREPARE_AN_ORDER_PAY_REQUEST_ERROR',
    //OrderPay
    ORDER_PAY_REQUEST_SUCCESS: 'ORDER_PAY_REQUEST_SUCCESS',
    ORDER_PAY_REQUEST: 'ORDER_PAY_REQUEST',
    ORDER_PAY_REQUEST_ERROR: 'ORDER_PAY_REQUEST_ERROR',
    //myOrders
    ORDERS_REQUEST_SUCCESS: 'ORDERS_REQUEST_SUCCESS',
    ORDERS_REQUEST: 'ORDERS_REQUEST',
    ORDERS_REQUEST_ERROR: 'ORDERS_REQUEST_ERROR',
    //myOrdersFree
    ORDERS_REQUEST_FREE_SUCCESS: 'ORDERS_REQUEST_FREE_SUCCESS',
    ORDERS_REQUEST_FREE: 'ORDERS_REQUEST',
    ORDERS_REQUEST_FREE_ERROR: 'ORDERS_REQUEST_FREE_ERROR',
    //current my order
    CURRENT_ORDER_REQUEST_SUCCESS: 'CURRENT_ORDER_REQUEST_SUCCESS',
    CURRENT_ORDER_REQUEST: 'CURRENT_ORDER_REQUEST',
    CURRENT_ORDER_REQUEST_ERROR: 'CURRENT_ORDER_REQUEST_ERROR',
    //my history operation
    MY_HISTORY_OPERATION_SUCCESS: 'MY_HISTORY_OPERATION_SUCCESS',
    MY_HISTORY_OPERATION: 'MY_HISTORY_OPERATION',
    MY_HISTORY_OPERATION_ERROR: 'MY_HISTORY_OPERATION_ERROR',
    //new service list
    NEW_SERVICE_LIST_SUCCESS: 'NEW_SERVICE_LIST_SUCCESS',
    NEW_SERVICE_LIST_REQUEST: 'NEW_SERVICE_LIST_REQUEST',
    NEW_SERVICE_LIST_ERROR: 'NEW_SERVICE_LIST_ERROR',
    //new service devices
    NEW_SERVICE_DEVICES_SUCCESS: 'NEW_SERVICE_DEVICES_SUCCESS',
    NEW_SERVICE_DEVICES_REQUEST: 'NEW_SERVICE_LIST_REQUEST',
    NEW_SERVICE_DEVICES_ERROR: 'NEW_SERVICE_DEVICES_ERROR',
    //products
    PRODUCTS_SUCCESS: 'PRODUCTS_SUCCESS',
    PRODUCTS_REQUEST: 'PRODUCTS_REQUEST',
    PRODUCTS_ERROR: 'PRODUCTS_ERROR',
    //products current
    PRODUCT_CURRENT_SUCCESS: 'PRODUCT_CURRENT_SUCCESS',
    PRODUCT_CURRENT_REQUEST: 'PRODUCT_CURRENT_REQUEST',
    PRODUCT_CURRENT_ERROR: 'PRODUCT_CURRENT_ERROR',
    //products current
    ORDER_UPDATE_REQUEST_SUCCESS: 'ORDER_UPDATE_REQUEST_SUCCESS',
    ORDER_UPDATE_REQUEST: 'ORDER_UPDATE_REQUEST',
    ORDER_UPDATE_REQUEST_ERROR: 'ORDER_UPDATE_REQUEST_ERROR',
    //preOrder current
    PRE_ORDER_REQUEST_SUCCESS: 'PRE_ORDER_REQUEST_SUCCESS',
    PRE_ORDER_REQUEST: 'PRE_ORDER_REQUEST',
    PRE_ORDER_REQUEST_ERROR: 'PRE_ORDER_REQUEST_ERROR',
    //preOrder current
    RESET_SUCCESS: 'RESET_SUCCESS',
}