import types from './../Types'
import { toast } from 'react-toastify';

const login = (values, callback) => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.USER_REQUEST });
    return api({
      method: 'POST',
      url: `/api/auth/login`,
      data: { login: values.email, password: values.password }
    }).then(
      (data) => {
        const needResetPassword = Boolean(data.data.resetPwd);
        localStorage.setItem('token', data.data.token);
        localStorage.setItem('refreshToken', data.data.refreshToken);    
        if (!needResetPassword) {        
          dispatch({ type: types.USER_LOGIN_SUCCESS, isLoggedIn: true, resetPassword: needResetPassword });
        } else {          
          dispatch({ type: types.USER_LOGIN_SUCCESS, isLoggedIn: false, resetPassword: needResetPassword });
        }        
      },
      ({ response }) => {
        toast.error('Неверный логин или пароль');
        dispatch({ type: types.USER_REQUEST_ERROR, error: response });
        callback && callback(response);
      }
    )
  };
}

const orgList = (cb, err_cb) => { // получение списка организаций
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.USER_REQUEST });    
    return api({
      method: 'POST',
      url: '/api/cabinet/my/org/list'
    }).then(
      ({data}) => {        
        dispatch({ type: types.USER_ORGLIST_REQUEST_SUCCESS, userOrgList: data });
        cb && cb(data)
      },
      ({ response }) => {
         err_cb && err_cb(response)
      },
    );
  };
}

const agreementList = (cb, err_cb) => { // получение списка договоров
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.USER_AGREEMENT_REQUEST });    
    return api({
      method: 'POST',
      url: '/apiv2/cabinet/view/BQ/UNP/Agreement'
    }).then(
      ({data}) => {    
        dispatch({ type: types.USER_AGREEMENT_SUCCESS, agreementList: data });        
        cb && cb(data);
      },
      ({ response }) => {
        dispatch({ type: types.USER_AGREEMENT_ERROR, error: response });
        err_cb && err_cb(response)
      },
    );
  };
}

const userInfo = (cb, err_cb) => { // инфо об аккаунте
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.USER_REQUEST });
    return api({
      method: 'POST',
      url: '/api/cabinet/my/dashboard'
    }).then(
      ({data}) => {
        dispatch({ type: types.USER_REQUEST_SUCCESS, user: data });
        cb && cb(data);
      },
      ({ response }) => {
        dispatch({ type: types.USER_REQUEST_ERROR, error: response });
        err_cb && err_cb(response);
      },
    );
  };
}

const logout = (callback) => {
  return (dispatch, getState, { api }) => {
    let token = localStorage.getItem('token');
    let refreshToken = localStorage.getItem('refreshToken');
    dispatch({ type: types.USER_IS_NOT_LOGGED_IN });

    return api({
      method: 'POST',
      url: '/api/auth/exit',
      data: JSON.stringify({
        token,
        refreshToken
      })
    }).then(
      (data) => {        
        callback && callback(data);
      },
      ({ response }) => {       
        callback && callback(response);
      },
    );
  };
}

const forgotPassword = (login, callback) => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.USER_REQUEST });

    return api({
      method: 'POST',
      url: `/api/cabinet/forgotPassword/${login}`,
      headers: { 'Authorization': '' }
    }).then(
      ({ data: { error, message } }) => {
        if (error === 0) {
          toast.success('Пароль выслан на e-mail');
          callback && callback();
        } else if (error === 1) {
          if (message && message !== '') toast.error(message)
          else toast.error('Не удалось отправить письмо на e-mail');
        }
        dispatch({ type: types.USER_REQUEST_ERROR, error: '' });
      },
      ({ response }) => {
        dispatch({ type: types.USER_REQUEST_ERROR, error: response });
      },
    );
  };
}

const resetPwd = (value, callback) => {
  return (dispatch, getState, { api }) => {
    dispatch({ type: types.USER_REQUEST });
    return api({
      method: 'POST',
      url: '/api/cabinet/resetPassword',
      data: {
        newPassword: value
      },
    }).then(
      ({ data: { error, message } }) => {        
        if (error === 1) {
          if (message && message !== '') toast.error(message)
          else toast.error('Ошибка. Сообщение об ошибке не найдено');
          dispatch({ type: types.USER_LOGIN_SUCCESS, isLoggedIn: false, resetPassword: true });
        } else {
          toast.success('Пароль успешно изменён');
          dispatch({ type: types.USER_LOGIN_SUCCESS, isLoggedIn: false, resetPassword: false });
          callback && callback();
        }      
      },
      ({ response }) => {               
        dispatch({ type: types.USER_LOGIN_SUCCESS, isLoggedIn: false, resetPassword: false });
        dispatch({ type: types.USER_REQUEST_ERROR, error: response })
      },
    );
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  orgList,
  agreementList,
  logout,
  userInfo,
  resetPwd,
  forgotPassword
};
