import types from './../Types'

const initialvalues = {
  isRequested: false,
  isRequestedPreOrder: false,
  isRequestedOrderPay: false,
  error: '',
  equipments: [],
  preOrder: [],
  preOrderParams: {},
  equipmentsLength: 0,
  preOrderLength: 0,
  prepareOrderPay: {},
  equipmentsActive: [],
  orderPay: {}
};

const services = (state = initialvalues, action) => {
  switch (action.type) {
    case types.PRE_ORDER_REQUEST:
      return { ...state, isRequestedPreOrder: true, error: '' };
    case types.PRE_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        isRequestedPreOrder: false,
        preOrder: action.preOrder,
        preOrderLength: action.preOrderLength,
        preOrderParams: action.preOrderParams
      };
    case types.PRE_ORDER_REQUEST_ERROR:
      return {
        ...state,
        isRequestedPreOrder: false,
        error: action.error,
      };
    case types.SERVICES_REQUEST:
      return { ...state, isRequested: true, error: '' };
    case types.SERVICES_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        equipments: action.equipments,
        equipmentsLength: action.equipmentsLength
      };
    case types.SERVICES_REQUEST_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    case types.DEVICE_REQUEST:
      return {
        ...state,
        isRequestedDevice: true,
        error: ''
      };
    case types.PREPARE_AN_ORDER_PAY_REQUEST:
      return {
        ...state,
        isRequestedOrderPay: true,
        error: ''
      };
    case types.PREPARE_AN_ORDER_PAY_REQUEST_SUCCESS:
      return {
        ...state,
        isRequestedOrderPay: false,
        prepareOrderPay: action.prepareOrderPay,
      };
    case types.PREPARE_AN_ORDER_PAY_REQUEST_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    case types.ORDER_PAY_REQUEST:
      return {
        ...state,
        isRequested: true,
        error: ''
      };
    case types.ORDER_PAY_REQUEST_SUCCESS:
      return {
        ...state,
        isRequested: false,
        orderPay: action.orderPay,
      };
    case types.ORDER_PAY_REQUEST_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    case types.RESET_SUCCESS:
      return {
        ...state,
        preOrderParams: {}
      };
    case types.EQUIPMENT_REQUEST:
      return {
        ...state,
        isEquipmentRequested: true,        
      };
    case types.EQUIPMENT_REQUEST_ERROR:
      return {
        ...state,
        isEquipmentRequested: false,
        error: action.error,
      };
    case types.EQUIPMENT_REQUEST_SUCCESS:
      return {
        ...state,
        equipmentList: action.itemsList || []
      };
    default:
      return state;
  }
}

export default services;
