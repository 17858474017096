import React, {useEffect, useState} from "react";
import { useDispatch, useSelector} from "react-redux";
import types from '../../redux/Types'
import authentication from "../../redux/actions/authentication";
import Modal from "../Modal";
import ModalContentSelect from "../Modal/components/ModalContentSelect";
import ModalContentInput from "../Modal/components/ModalContentInput";
import service from "../../redux/actions/authentication"


const SelectOrganization = () => {

	const dispatch = useDispatch();
	const [localUnp, setLocalUnp] = useState(localStorage.getItem("unp") || "");
	const [userOrgList, setUserOrgList] = useState([])
	const { isAdmin} = useSelector((state) => state.authentication);	

	useEffect(() => {		
		dispatch(service.orgList((data) => {
			let isAdmin = data[0]?.roleId === 2 && data[0]?.unp === "ECSAT"
			if(isAdmin){
				localStorage.setItem('isAdmin', 1)	
				dispatch({ type: types.USER_IS_ADMIN});
			}
			if (data.length === 1 && !isAdmin) {
				let org = data[0];
				localStorage.setItem('unp', org.unp)				
				dispatch({ type: types.USER_ORG_SELECTED, org: org});
			} 
			setUserOrgList(data)       
		}))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleUnpChange = (e) => {
		setLocalUnp(e.target.value);
	};
	const handleOrganizationChange = () => {	
		let selectedOrg = isAdmin && localUnp ? {unp: localUnp} : userOrgList.find(x => x.unp === localUnp)
		if(selectedOrg){		
			localStorage.setItem("unp", selectedOrg.unp);			
			dispatch({ type: types.USER_ORG_SELECTED, org: selectedOrg});
		}
	};
	
	return (
		<>
			{isAdmin ? (
				<Modal
					open={true}
					close={() => dispatch(authentication.logout())}
					titleButton='отправить'
					titleButton2='закрыть'
					buttonClick={handleOrganizationChange}
					disabled={localUnp === "" ? true : false}
				>
					<ModalContentInput value={localUnp} onChange={handleUnpChange} />
				</Modal>
			) :
			(
				<Modal
					open={true}
					close={() => dispatch(authentication.logout())}
					titleButton='продолжить'
					titleButton2='закрыть'
					buttonClick={handleOrganizationChange}
				>
					<ModalContentSelect
						value={localUnp}
						onChange={handleUnpChange}
						arr={userOrgList}
						isOpen
					/>
				</Modal>
			)}
		</>
	);
};

export default SelectOrganization;
