import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';

const Store = (extra) => {
  const middlewares = [thunk.withExtraArgument(extra)];
  let store;
  store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );  
  return store;
}

export default Store;
