import React from "react";
import cls from "./modal.module.scss";
import ButtonStyle from "../ButtonStyle";
import { CircularProgress, Dialog } from "@material-ui/core";

const Modal = ({
	open,
	close,
	disabled,
	children,
	titleButton,
	titleButton2,
	buttonClick,
	styleButton,
	isLoading,
	styleVariableButton,
	styleVariableButton2,	
	...props
}) => {
	return (
		<Dialog
			open={open}
			onClose={close}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			className={cls.modal_wrapper}
			{...props}
		>
			<div className={cls.modal_content_wrapper}>
				<div
					className={cls.close}
					onClick={close}
				/>
				{isLoading ? (
					<CircularProgress className={cls.modal_loading}/>
				):( <>
						{children}
				</>)}
			</div>

			{(!!titleButton || !!titleButton2)&&(<div className={`${cls.modal_button_block} ${titleButton ? cls.modal_button_block_two : ""}`}>
							{titleButton && (
									<ButtonStyle
										styleVariable={ styleVariableButton || (styleButton ? "default" : "outlined")}
										onClick={buttonClick}
										disabled={disabled}
									>
										{titleButton}
									</ButtonStyle>
								)}
								{titleButton2 && (
									<ButtonStyle
										styleVariable= {styleVariableButton2 || 'outlined'}
										onClick={close}
									>
										{titleButton2}
									</ButtonStyle>
								)}
			</div>)}
		</Dialog>
	);
};
export default Modal;
