import React from "react";
import { useSelector } from "react-redux";
import cls from "./footer.module.scss";
// import SelectorLang from "./SelectorLang";
import { useTranslation } from "react-i18next";
import json from "../../../package.json";

const Footer = () => {
	const { t } = useTranslation();

	const {isAuthorized } = useSelector(
		(state) => state.authentication
	);

	const date = new Date().getFullYear();
	return isAuthorized ? (
		<footer className={cls.footer_container}>
			<div className={cls.footer_container__left}>
				<span>
					{t("Разработчик программного обеспечения  - ООО «ЭКСАТ-ЛАБ»")}
				</span>
				<span>
					{t("Поставщик оборудования и сервисных услуг - СООО «ЭКСАТ-БЕЛ»")}
				</span>
			</div>

			<div className={cls.footer_container__right}>
				<span className={cls.footer_date}>
					{date} {t("г.")}
				</span>
				{/* <span className={cls.footer_lang}>
					{t("Версия")}: <SelectorLang />
				</span> */}
			</div>

			<div className={cls.version}>v. {json.version}</div>
		</footer>
	) : "";
};

export default Footer;
