import React, { useState, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@material-ui/core";
import { push as Menu } from "react-burger-menu";
import cls from "./header.module.scss";
import authentication from "../../redux/actions/authentication";
import HamburgerMenu from "../../components/HamburgerMenu";
import SettingsIcon from "@material-ui/icons/Settings";
import UserRoleType from "../../roleType";

const Header = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const roleType = UserRoleType;
	const {user, isAuthorized, role, hasPreorder} = useSelector(
		(state) => state.authentication
	);
	const [localUnp] = useState(localStorage.getItem("unp") || "");	
	const [arrayMenu, setArrayMenu] = useState([]);
	const [buttonBurger, setButtonBurger] = useState(false);
	const [styleLeft, setStyleLeft] = useState({ left: 0, width: 0 });

	useMemo(() => {

		if(!user || !role){
			return;
		}
		let menu = [{ id: 1, name: t("Главная"), path: "/" }];
		if(user.isMultiAcquirer){
			menu.push({ id: 4, name: t("Мои транзакции"), path: "/transactions" })			
		}
		switch(role){
					//old menu flow
					//TODO посмотреть что там с транзакциями
					case roleType.СОSAAS:
					case roleType.SaaS:
						if(user.isSAAS){
							menu = [...menu, ...[
								{ id: 2, name: t("Моё оборудование"), path: "/equipment" },
								{ id: 3, name: t("Мои заказы"), path: "/orders" },
							]]
						}
						menu = [...menu, ...[
							{ id: 5, name: t("Вопрос/ответ"), path: "/faq" },
						]]
						break;
					case roleType.Rent:
						menu = [...menu, ...[
							{ id: 2, name: t("Акты"), path: "/acts" },
							{ id: 3, name: t("Пополнения"), path: "/history" },
						]]
						break;
					case roleType.CO:
						menu = [...menu, ...[
							{ id: 2, name: t("Акты"), path: "/acts" },
							{ id: 3, name: t("Пополнения"), path: "/history" },
							{ id: 4, name: t("Архив"), path: "/archive" },							
						]]
						break;
					case role.Multi:
						break;
					default:
						break;
				}
		
		if(hasPreorder){
			menu.push({ id: 10, name: t("Предзаказ"), path: "/preorder" })
		}
		
		if(role === roleType.Admin){
			menu.push({ id: 11, name: t("Настройки"), path: "/settings" })
		}
		setArrayMenu(menu);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, role, hasPreorder]);

	const arrayMenuExt = [
		{ id: 1, name: t("Тарифы"), path: "https://zpos.by/#tariffs" },
		{ id: 2, name: t("Контакты"), path: "https://zpos.by/#contacts" },
	];

	const handleMouseEnter = (e) => {
		setStyleLeft({ left: e.target.offsetLeft, width: e.target.offsetWidth });
	};

	// useEffect(() => {
	// 	swipeHandler(setButtonBurger);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const menuContent = (
		<>
			{arrayMenu.map((el) => (
				<div
					key={el.id}
					className={cls.header_tab}
					onMouseEnter={handleMouseEnter}
					onClick={() => setButtonBurger(false)}
				>
					<NavLink
						onClick={() => window.scrollTo({ top: 0 })}
						exact
						className={cls.header_tab_all}
						activeClassName={cls.header_tab_active}
						to={el.path}
					>
						{el.name}
					</NavLink>
				</div>
			))}
			{arrayMenuExt.map((el) => {
				return (
					<div
						key={el.id}
						className={cls.header_tab}
						onMouseEnter={handleMouseEnter}
						onClick={() => setButtonBurger(true)}
					>
						<a
							className={cls.header_tab_all}
							href={el.path}
							target='_blank'
							rel='noopener noreferrer'
						>
							{el.name}
						</a>
					</div>
				);
			})}
			<div className={cls.header_tab} onMouseEnter={handleMouseEnter}>
				<NavLink
					className={cls.header_tab_all_last}
					to='/'
					onClick={() => dispatch(authentication.logout())}
				>
					{t("Выход")}
				</NavLink>
			</div>
		</>
	);

	return (
		<>
			{isAuthorized && (
				<>
				<header className={cls.header_container}>
					<Container component='main' className={cls.container}>
						<NavLink to={"/"}>
							<div className={cls.header_logo_container}>
								
							</div>
						</NavLink>						
						<div className={cls.header_button_burger}>
							<div
								className={cls.menu_wrapper}
								onClick={() => setButtonBurger(!buttonBurger)}
							>
								<HamburgerMenu boolean={buttonBurger} />
							</div>
						</div>
						<div
							onMouseLeave={() => setStyleLeft({ ...styleLeft, width: 0 })}
							className={`${cls.header_tabs_container}`}
						>
							{menuContent}
							<span className={cls.header_line} style={styleLeft} />
						</div>
						
						<NavLink
							exact
							className={cls.header_button_settings}
							activeClassName={cls.header_tab_active}
							to='settings'
						>
							<SettingsIcon />
						</NavLink>
						
						<div className={cls.header_user_info}>
							<div className={cls.header_user_info_org}><div>{user.orgShortName}</div><div>УНП {localUnp} </div></div>
							<div className={cls.header_user_info_user}>{user.mainUserPersonName}</div>
						</div>
					</Container>
				</header>

				<div className={cls.menu_conatiner_wrapper}>
					<div id='outer-container'>
						<Menu
							outerContainerId={"outer-container"}
							onStateChange={({ isOpen }) => {
								setButtonBurger(isOpen);
							}}
							right
							isOpen={buttonBurger}
							customBurgerIcon={false}
						>
							{menuContent}
						</Menu>
					</div>
				</div>
				</>				
			)}
		</>
	);
};

export default Header;
