import types from './../Types'

const initialvalues = {
  isLoggedIn: false,
  isRequested: false,
  isAuthorized: false,
  isAdmin: false,  
  hasPreorder: false,
  role: undefined,
  isOrganizationSelectRequired: false,
  resetPassword: false,
  error: '',
  unp: '',  
  agrId: undefined,
  user: {},
  userOrgList: [],
  agreementList: [],
  needRefreshUserInfo: false,
  stateRefresher: 0
};

const authentication = (state = initialvalues, action) => {
  switch (action.type) {
    case types.USER_REQUEST:
      return { ...state, isRequested: true, error: '' };
    case types.USER_HAS_PREORDER:
      return { ...state, hasPreorder: true};
    case types.USER_LOGIN_SUCCESS:      
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,//time
        isRequested: false,
        resetPassword: action.resetPassword,
        stateRefresher: Math.random()
      };
    case types.USER_REQUEST_ERROR:
      return {
        ...state,
        isRequested: false,
        error: action.error,
      };
    case types.USER_REQUEST_SUCCESS:      
      return {
        ...state,
        user: action.user,
      };
    case types.USER_IS_ADMIN:      
      return {
        ...state,
        isAdmin: true,
      };
    case types.USER_IS_AUTHORIZED:      
      return {
        ...state,
        isAuthorized: true,
        isLoggedIn: true,
        role: action.role,
        stateRefresher: Math.random()
      }
    case types.USER_ORG_SELECTED:            
      return {
        ...state,
        unp: action.org.unp,        
    }
    case types.USER_ORGLIST_REQUEST_SUCCESS:      
    return {
      ...state,
      userOrgList: action.userOrgList    
    }
    case types.USER_IS_NOT_LOGGED_IN:
      localStorage.clear()      
      return {...initialvalues,  stateRefresher: Math.random()};
    case types.USER_AGREEMENT_SUCCESS:
      let selectedAgrId = action.agreementList.length === 1 ? action.agreementList[0].agrID : undefined;
      localStorage.setItem("argList", JSON.stringify(action.agreementList))   
      return {
        ...state,
        agreementList: action.agreementList,
        agrId: selectedAgrId
      }
    case types.USER_AGREEMENT_CHANGED:
      localStorage.setItem("agrId", action.agrId)   
      return  {
        ...state,
        agrId: action.agrId
      }
    case types.USER_NEED_REFRESH:
      return  {
        ...state,
        needRefreshUserInfo: true        
      }
    case types.USER_REFRESH_SUCCESS:
      return  {
        ...state,
        needRefreshUserInfo: false        
      }
    
    default:
      return state;
  }
}

export default authentication;
